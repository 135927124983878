<template>
    <div class="etalent" v-if="eTalent != null">
        <div class="row">
            <div class="col-md-4 col-sm-12 align-items-center">
                <div class="box-talent my-2 d-flex justify-content-center align-items-center">
                    <div class="d-flex align-items-center flex-column">
                        <p class="etalent-desc text-center" style="font-weight: 700; font-size: 24px; color: #969A9D">{{ eTalent.Talent.Talentname }}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="196" height="110" viewBox="0 0 196 110" fill="none">
                            <line x1="5.47656" y1="55.0447" x2="195.111" y2="55.0447" stroke="#C6CFD2" stroke-width="2"/>
                            <line y1="-2" x2="62.671" y2="-2" transform="matrix(0.699118 -0.715007 0.699118 0.715007 5.47656 88.252)" stroke="#969A9D" stroke-width="4"/>
                            <line y1="-2" x2="83.5736" y2="-2" transform="matrix(0.647135 0.762376 -0.74779 0.663935 45.8682 43.4419)" stroke="#969A9D" stroke-width="4"/>
                            <line y1="-2" x2="130.055" y2="-2" transform="matrix(0.631673 -0.775235 0.761085 0.648652 100.636 107.156)" stroke="#969A9D" stroke-width="4"/>
                            <ellipse cx="100.294" cy="104.706" rx="5.1345" ry="5.25119" fill="#969A9D"/>
                            <ellipse cx="181.761" cy="5.98337" rx="5.1345" ry="5.25119" fill="#969A9D"/>
                            <ellipse cx="46.8952" cy="42.3916" rx="5.1345" ry="5.25119" fill="#969A9D"/>
                            <ellipse cx="5.1345" cy="87.9018" rx="5.1345" ry="5.25119" fill="#969A9D"/>
                        </svg>
                    </div>
                </div>
                
                <p class="etalent-sum" v-html="eTalent.Talent.Summary"></p>
            </div>
            <div class="col-md-8 col-sm-12">
                <h4 class="etalent-title">
                    Talento {{ eTalent.Talent.Talentname }}
                </h4>
                <p class="etalent-desc" style="font-weight: 600 !important;">{{ eTalent.Talent.Motivation }}</p>
                <p class="etalent-desc my-4">
                    {{ eTalent.Talent.Description }}
                </p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ETalentVisualizer',
    props: {
        canEdit: {
            default: false
        },
        eTalent: {
            default: null
        }
    },
}
</script>
<style scoped lang="scss">

    .etalent {
        display: flex;
        width: 100%;
        height: 100%;
        padding: 55px;
    }

    .etalent-share {
        font-family: "Hanken Grotesk", sans-serif;
        color: #B1B1B1;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
    }

    .etalent-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
        font-family: "Hanken Grotesk", sans-serif;
        color: var(--buttons_color);
    }

    .etalent-desc {
        font-size: 16px;
        font-weight: 400;
        font-family: "Hanken Grotesk", sans-serif;
        line-height: 20px;
        color: #706F6F;
    }

    .etalent-sum {
        font-size: 16px;
        font-weight: 400;
        font-family: "Hanken Grotesk", sans-serif;
        line-height: 16px;
        color: #706F6F;
    }

    .box-talent {
        width: 100%;
        height: 230px;
        flex-shrink: 0;
        border-radius: 20px;
        background: #DFEEF1;
    }

    .box-talent svg {
        width: 100%;
    }
</style>